.ciallo {
  margin: 0;
  padding-left: 100%;
  white-space: nowrap;
  animation: ciallo-animate 15s linear infinite;
}

@keyframes ciallo-animate {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-150%, 0); /* 将文字移得更远，确保完全离开屏幕 */
  }
}
